<template>
  <div>
    <v-dialog
      v-model="visible"
      transition
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="600px"
      height="100%"
    >
      <!--max-width="100%"-->
      <v-card
        tile
        elevation="10"
        class="rounded-0 top-rounded"
        min-height="100%"
        max-height="100%"
        :height="$vuetify.breakpoint.smAndUp ? '90vh' : '100%'"
      >
        <!--min-height="80%" max-height="70%"-->
        <v-card-title
          v-touch="{
            down: () => swipeDown(),
          }"
          class="text-center pt-2"
        >
          <!--dividers-->
          <i id="top-divider" class="mx-auto mt-2"></i>
        </v-card-title>

        <v-card-title>
          <v-row align="center" justify="center" no-gutters>
            <!--start close button-->
            <v-col cols="3">
              <v-btn
                @click="visible = false"
                small
                fab
                elevation="0"
                color="gray lighten-2"
              >
                <v-icon color="primary">mdi-close</v-icon>
              </v-btn>
            </v-col>
            <!--end close button-->

            <v-col cols="6" class="text-center text-dark">
              <h4>
                {{$t("delivery.lang_filters")}}
              </h4>
            </v-col>

            <!--start refresh button-->
            <v-col class="text-right" cols="3">
              <v-btn
                @click="clear"
                small
                fab
                class="mx-auto pa-0"
                elevation="0"
              >
                <v-icon class="text-muted">mdi-refresh</v-icon>
              </v-btn>
            </v-col>
            <!--end refresh button-->
          </v-row>
        </v-card-title>

        <v-card-text class="pt-4">
          <!-- start search input -->
          <v-text-field
            v-model="search"
            :label="$t('delivery.lang_searchItem')"
            dense
            outlined
            clearable
            prepend-inner-icon="mdi-magnify"
          />
          <!-- start search input -->

          <!-- start price range -->
          <v-row align="center" justify="center" no-gutters class="pa-0">
            <v-col cols="6">
              <h5 class="text-muted">{{$t("delivery.lang_priceRange")}}</h5>
            </v-col>

            <v-col class="text-right" cols="6">
              <h6 class="primary--text">
                {{ price[0] | currency }} - {{ price[1] | currency }}
              </h6>
            </v-col>
          </v-row>

          <v-range-slider
            v-model="price"
            tick-size="100"
            :max="1000"
            :min="1"
            class="align-center pb-0 mb-0"
          ></v-range-slider>
          <!-- end price range -->

          <!-- start sort by -->
          <h5 class="pt-0 mt-0 text-muted">{{$t("delivery.lang_sortBy")}}</h5>

          <v-chip-group v-model="sortBy" color="primary">
            <v-chip
              class="rounded-lg"
              :value="sort.value"
              v-for="sort in sorts"
              :key="sort.name"
            >
              {{ sort.name }}
            </v-chip>
          </v-chip-group>
          <!-- end sort by -->

          <!-- start meal types -->
          <div
            v-if="Array.isArray(this.mealTypes) && this.mealTypes.length > 0"
          >
            <h5 class="pt-0 mt-0 pt-2 text-muted">{{$t("delivery.lang_mealTypes")}}</h5>

            <v-chip-group
              v-model="selectedMealTypes"
              color="primary"
              multiple
              column
            >
              <v-chip
                :value="type.uuid"
                v-for="type in mealTypes"
                :key="type.name"
                filter-icon="check"
                class="rounded-lg"
              >
                {{ type.name }}
              </v-chip>
            </v-chip-group>
          </div>
          <!--end meal types-->

          <!--start allergens-->
          <v-expansion-panels class="elevation-0 pa-0 ma-0 pt-2">
            <v-expansion-panel class="elevation-0 pa-0 ma-0">
              <v-expansion-panel-header class="elevation-0 pa-0 ma-0">
                <h5 class="text-muted">{{$t("delivery.lang_allergens")}}</h5>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-checkbox
                  v-model="selectedAllergens"
                  :value="allergen.id"
                  v-for="allergen in allergens"
                  :key="allergen.id"
                  :label="allergen.name"
                  class="pa-0 ma-0"
                  color="primary"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <!--start apply button-->
        <v-card-actions>
          <v-btn
            elevation="0"
            large
            color="primary"
            block
            class="mx-auto shadow-lg"
            @click="apply"
            :loading="this.loading"
            :disabled="this.loading"
          >
            {{$t("delivery.lang_apply")}}
          </v-btn>
        </v-card-actions>
        <!--end apply button-->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/*import plugins*/
import ENDPOINTS from "@/plugins/axios/endpoints";

export default {
  name: "FilterComponent",

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    loadingAllergens:false,
    price: [0, 100],
    sortBy: [],
    search: "",
    selectedAllergens: [],
    selectedMealTypes: [],
    allergens: [],
    sorts: [],
    mealTypes: [],
    loading: false,
  }),

  computed: {
    visible: {
      get() {
        return this.dialog;
      },
      set(val) {
        /*if the dialog will be closed we'll clear filters only from the current component*/
        if (!val) this.clear();

        this.$emit("input", val);
      },
    },
  },

  watch: {
    dialog(val) {
      if (val) this.initFilters();
    },
  },

  methods: {
    /*clear all filters*/
    clear() {
      this.price = [0, 100];
      this.sortBy = [];
      this.selectedAllergens = [];
      this.selectedMealTypes = [];
      this.search = "";
    },

    /*change filter values in vuex*/
    apply() {
      this.$store.commit("Filter/setSortBy", this.sortBy);
      this.$store.commit("Filter/setAllergens", this.selectedAllergens);
      this.$store.commit("Filter/setMealTypes", this.selectedMealTypes);
      this.$store.commit("Filter/setPrice", this.price);
      this.$store.commit("Filter/setSearch", this.search);

      const self = this;

      this.loading = true;
      let timer = setTimeout(() => {
        self.loading = false;
        self.visible = false;
        clearTimeout(timer);
      }, 2000);
    },
    /*get all allergens*/
    async loadAllergens() {
      this.loadingAllergens=true;
      this.axios.get(ENDPOINTS.ERP.ALLERGENS.GET_ALL).then(res=>{
        if(res.status===200){
          this.allergens = res.data.data;
        }
      }).finally(()=>{
        this.loadingAllergens=false;
      })
    },

    /*close dialog on swipe down*/
    swipeDown() {
      this.visible = false;
    },

    /*load data from api and vuex on open dialog*/
    initFilters() {
      /*load meal types*/
      /*load allergens*/
      this.loadAllergens();

      /*init filters from vuex*/
      this.search = this.$store.getters["Filter/search"];
      this.sortBy = this.$store.getters["Filter/sortBy"];
      this.selectedAllergens = this.$store.getters["Filter/allergens"];
      this.price = this.$store.getters["Filter/price"];
      this.selectedMealTypes = this.$store.getters["Filter/mealTypes"];
      this.sorts = this.$store.getters["Filter/sorts"];
    },
  },

  mounted() {
    this.initFilters();
  },
};
</script>

<style scoped>
.top-rounded {
  border-top-left-radius: 50px !important;
  border-top-right-radius: 50px !important;
}

#top-divider {
  width: 50px;
  height: 4px;
  background-color: lightgrey;
  border-radius: 30px;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>

<style>
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  top: 5px !important;
}
</style>
