<template>
  <div v-scroll="scrolled" id="tabs" class="mt-10">
    <!--Start Products Category Tabs-->
    <v-app-bar :app="isFixedTop" height="40px" dense flat>
      <v-tabs
        ref="tabs"
        v-model="tab"
        height="40px"
        dark
        grow
        dense
        center-active
        show-arrows
        color="white"
        background-color="grey"
      >
        <template v-for="category in filteredItemGroups">
          <v-tab
            v-if="category.items.length > 0"
            @click="scrollToItems('category_' + category.id)"
            :key="category.id"
          >
            {{ category.name }}
          </v-tab>
        </template>
      </v-tabs>
    </v-app-bar>
    <!--End Products Category Tabs-->

    <!-- loader -->
    <v-progress-linear v-if="loading" indeterminate color="primary" />
    <!-- end loader -->

    <!--Start Products Card-->
    <div class="mb-12 pb-5 pt-2" width="100%">
      <div
        :id="'category_' + category.id"
        v-for="(category, index) in filteredItemGroups"
        :key="category.id"
        width="100%"
      >
        <v-sheet
          v-if="category.items.length > 0"
          elevation="0"
          color="tertiary lighten-3"
          class="primary--text rounded mx-3 mt-6 mb-5 my-10 pl-0 py-0"
        >
          <!-- start category background-->
          <div
            v-if="category && category.image"
            class="rounded-tr-lg rounded-tl-lg overflow-hidden"
            :style="{
              height: $vuetify.breakpoint.xs
                ? '130px'
                : $vuetify.breakpoint.sm
                ? '150px'
                : '200px'
            }"
          >
            <img :src="imageDim(category.image)" width="100%" />
          </div>
          <!-- end category background-->

          <!--Start category name-->
          <h6 class="py-5 pl-4 font-weight-bold body-1">
            {{ category && category.name }}
            <strong
              v-if="
                isDelivery
                  ? category['delivery discount']
                  : category['takeaway discount']
              "
              class="error--text"
            >
              {{
                " (-" +
                  (isDelivery
                    ? category["delivery discount"]
                    : category["takeaway discount"]) +
                  "%)"
              }}
            </strong>
          </h6>
          <!--Start category name-->
        </v-sheet>

        <!--Start item card-->
        <v-sheet
          v-if="category.items && category.items.length > 0"
          v-intersect="{ handler: onIntersect, options: { threshold: [1.0] } }"
          :id="index"
        >
          <menu-items-card
            @click="snackbar = maintenanceMode"
            v-for="item in category.items"
            :key="item.id + index"
            :item="item"
            :item-group="category"
          />
        </v-sheet>
        <!--end item card-->
      </div>

      <v-card-text v-if="dynamicContent" v-html="dynamicContent.imprints">
      </v-card-text>
    </div>
    <!--End Products Card-->

    <!--Start Shopping Cart-->
    <shopping-cart
      v-if="this.$vuetify.breakpoint.smAndDown && this.$route.path === '/'"
      v-model="shoppingCartDialog"
      :dialog="this.shoppingCartDialog"
    />
    <!--End Shopping Cart-->
    <!--start close lose message dialog-->
    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card tile elevation="0" class="pb-1">
        <v-card-title>
          {{ $t("delivery.lang_information") }}
        </v-card-title>

        <v-card-text class="mt-5">
          <p class="mb-4">
            {{ this.alternativeText }}
          </p>

          <span class="black--text">{{
            $t("delivery.lang_openingTimes")
          }}</span>
          <br />
          <span v-if="openingTime.length > 0" v-html="openingTime"> </span>
          <br />
          <router-link to="/info"
            >{{ $t("delivery.lang_storeInfo") }}
          </router-link>
        </v-card-text>

        <v-card-actions>
          <v-btn
            v-show="this.maintenanceMode"
            text
            color="accent darken-1"
            class="text-capitalize"
            @click="$router.push('/info') && (dialog = !dialog)"
          >
            {{ $t("delivery.lang_deliveryTimes") }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            elevation="0"
            color="accent darken-1"
            class="px-6 text-capitalize rounded-xl"
            @click="toggleService()"
          >
            {{
              this.maintenanceMode ||
              !this.isStillOpen ||
              !this.enableOrderScheduling ||
              (!this.offersDelivery && !this.offersPickUp)
                ? $t("delivery.lang_ok")
                : !this.offersDelivery
                ? $t("delivery.lang_pickup")
                : !this.offersPickUp
                ? $t("delivery.lang_delivery")
                : $t("delivery.lang_ok")
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end close lose message dialog-->
    <!--start close notification snackbar-->
    <div>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        rounded
        centered
        color="accent"
        class="pa-2 mx-auto text-center"
      >
        <template v-slot:action="{ attrs }">
          <v-icon @click="snackbar = false" class="ma-auto">
            mdi-close
          </v-icon>
        </template>
        <p class="px-6 py-4 mb-0 text-center ">
          {{ shop.name + " " + $t("generic.lang_resClosedMsg") }}
        </p>
      </v-snackbar>
    </div>
    <!--end close notification snackbar-->
  </div>
</template>

<script>
/*import plugins*/
import { createNamespacedHelpers } from "vuex";

/*import components*/
import MenuItemsCard from "@/components/Menu/MenuItemsCard";
import ShoppingCart from "@/components/Card/ShoppingCart";
import moment from "moment";
import { isBreakfast } from "../../helpers/breakfast";

export default {
  name: "MenuItems",

  components: { MenuItemsCard, ShoppingCart },

  data() {
    return {
      tab: null,
      snackbar: false,
      timeout: 3000,
      dialog: false,
      shoppingCartDialog: false,
      loading: false,
      isFixedTop: false
    };
  },

  computed: {
    ...createNamespacedHelpers("Config").mapGetters([
      "maintenanceMode",
      "offersDelivery",
      "offersPickUp",
      "alternativeText",
      "openingTimes",
      "isDelivery",
      "shop",
      "dynamicContent",
      "enableOrderScheduling",
      "isStillOpen",
      "now"
    ]),

    ...createNamespacedHelpers("Order").mapGetters(["isCartEmpty"]),
    ...createNamespacedHelpers("Filter").mapGetters(["search"]),
    ...createNamespacedHelpers("Loader").mapGetters(["loader"]),
    ...createNamespacedHelpers("ItemGroups").mapGetters(["itemGroups"]),
    filteredItemGroups() {
      return this.itemGroups.filter(itemGroup => {
        return itemGroup.items && itemGroup.items.length > 0;
      });
    },
    openingTime() {
      let now = moment(this.now, "DD:MM:YYYY HH:mm");
      let today = now.weekday();
      let hours = "";
      if (this.openingTimes && this.openingTimes.length > 0) {
        if (this.openingTimes.length > today) {
          if (this.openingTimes[today].open) {
            let start1 =
              this.openingTimes[today]["shift1"].start !== "00:00:00.000000"
                ? this.openingTimes[today]["shift1"].start.substring(0, 5)
                : null;
            let end1 =
              this.openingTimes[today]["shift1"].end !== "00:00:00.000000"
                ? this.openingTimes[today]["shift1"].end.substring(0, 5)
                : null;
            let start2 =
              this.openingTimes[today]["shift2"].start !== "00:00:00.000000"
                ? this.openingTimes[today]["shift2"].start.substring(0, 5)
                : null;
            let end2 =
              this.openingTimes[today]["shift2"].end !== "00:00:00.000000"
                ? this.openingTimes[today]["shift2"].end.substring(0, 5)
                : null;

            if (this.openingTimes[today]["shift1"].open && start1 && end1) {
              hours = start1 + " - " + end1 + "<br/>";
            }

            if (this.openingTimes[today]["shift2"].open && start2 && end2) {
              hours += start2 + " - " + end2;
            }
          }
        }
      }
      return hours;
    }
  },

  watch: {},

  methods: {
    ...createNamespacedHelpers("Config").mapMutations(["setDelivery"]),

    ...createNamespacedHelpers("Order").mapMutations(["setItemPrices"]),

    ...createNamespacedHelpers("Config").mapMutations(["setDeliveryInfo"]),

    onIntersect(entries, observer) {
      if (entries && observer) {
        if (entries[0].isIntersecting) {
          this.tab = Number(entries[0].target.id);
        }
      }
    },
    toggleService() {
      if (this.$route.path !== "/") {
        if (!(!this.isCartEmpty && !this.maintenanceMode)) {
          this.$router.push("/");
          this.dialog = false;
          return;
        }
      }

      if (this.offersDelivery) {
        this.setDelivery(true);
        this.setItemPrices(true);
      } else if (this.offersPickUp) {
        this.setDelivery(false);
        this.setItemPrices(false);
      }

      this.dialog = false;
    },

    scrolled() {
      this.isFixedTop =
        document.getElementById("tabs").offsetTop <=
        (window.pageYOffset || document.documentElement.scrollTop);

      if (this.isFixedTop === true) {
        this.isFixedTop = true;
      } else {
        this.isFixedTop = false;
      }
    },

    //scroll to specific item/section
    scrollToItems(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
    imageDim(logo) {
      let baseUrl = "https://img.3pos.de/unsafe/";
      let url = logo.substring(baseUrl.length);
      let filePath = url.substring(url.split("/")[0].length);

      if (this.$vuetify.breakpoint.xs) {
        return baseUrl + "200x100" + filePath;
      } else if (this.$vuetify.breakpoint.sm) {
        return baseUrl + "400x250" + filePath;
      } else {
        return baseUrl + "600x350" + filePath;
      }
    }
  },

  beforeMount() {
    this.loading = true;
    this.$store.dispatch("ItemGroups/loadItemGroups").finally(() => {
      this.loading = false;
    });
  },

  mounted() {
    this.$root.$on("showCloseMSGDialog", () => {
      if (this.maintenanceMode || (!this.isStillOpen && !this.enableOrderScheduling)) {
        this.dialog = true;
      }

      if (!this.maintenanceMode && this.offersDelivery && !isBreakfast()) {
        this.setDelivery(true);
        this.setItemPrices(true);
      } else if (!this.maintenanceMode && !this.offersDelivery) {
        this.setDelivery(false);
        this.setItemPrices(false);
      }
    });

    this.tab = 0;
  },

  beforeDestroy() {
    this.$root.$off("showCloseMSGDialog");
  }
};
</script>

<style>
#fixed-tabs-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
}
</style>
