<template>
  <div>
    <v-dialog v-model="visible" :overlay-opacity="0.8" transition scrollable max-width="100%" height="100%"
      :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card tile elevation="10" class="bg rounded-0 fixed-bottom position-fixed" min-height="100%" max-height="0%"
        :height="$vuetify.breakpoint.smAndUp ? '90vh' : '100%'">
        <!-- header background -->
        <div id="header_image" :style="{
            backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(0, 0, 0, 0.73)), url('${this.item.image?this.item.image:images && images.productDefaultImage}')`,
          }">
          <!--close button-->
          <v-btn elevation="0" min-width="40" max-width="40" min-height="40" max-height="40" small id="back_btn"
            color="#ffffffab" class="pa-0 px-0 mt-3 ml-3 rounded-xl" @click="visible = false">
            <v-icon color="primary" class="pa-0">
              mdi-window-close
            </v-icon>
          </v-btn>
        </div>

        <!--start title icon-->
        <v-card-title v-touch="{
            down: () => swipeDown(),
          }" class="text-left bg top-rounded mb-0 white" style="margin-top: -50px">
          <i id="top-divider" class="mx-auto mt-3"></i>

          <div class="text-h6" style="word-break: normal;">
          {{ item.name }}
        </div>
        </v-card-title>
        <!--end title icon-->
        <v-card-text>
          <!--description-->
          <div v-if="productInfoSettings.showProductInfo && !productInfoSettings.showStandardProductInfo"
            class="pt-3 text-wrap" v-html="this.item.description">
          </div>
          <div
            v-html="productInfoSettings.standardProductInfo && productInfoSettings.standardProductInfo['standardProductInfo_' + $i18n.locale]"
            class="pt-3">

          </div>
        </v-card-text>
        <v-card-text v-if="productInfoSettings.showAllergens&&!productInfoSettings.showStandardProductInfo">
          <!--start allergens-->
          <v-expansion-panels mandatory class="elevation-0 bg pa-0 ma-0 pt-2">
            <v-expansion-panel class="elevation-0 bg pa-0 ma-0">
              <v-expansion-panel-header class="elevation-0 pa-0 ma-0">
                <h6 class="text-muted">{{ $t('delivery.lang_allergens') }}</h6>
              </v-expansion-panel-header>

              <v-expansion-panel-content class="bg">
                <v-list v-if="
                    Array.isArray(this.item.allergenes) &&
                      this.item.allergenes.length > 0
                  " class="bg">
                  <v-list-item v-for="allergen in this.item.allergenes" :key="allergen.id" class="pt-0 pb-0">
                    <v-list-item-icon>
                      <v-icon color="accent" small>mdi-square</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title class="text-danger">
                        {{ allergen.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <p class="success--text" v-else>
                  {{$t('generic.lang_itemWithoutAllergens')}}
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!--end allergens-->
          <!--start additives-->
          <v-expansion-panels mandatory class="elevation-0 bg pa-0 ma-0 pt-2">
            <v-expansion-panel class="elevation-0 bg pa-0 ma-0">
              <v-expansion-panel-header class="elevation-0 pa-0 ma-0">
                <h6 class="text-muted">{{ 'Additive' }}</h6>
              </v-expansion-panel-header>

              <v-expansion-panel-content class="bg">
                <v-list v-if="
                    Array.isArray(this.item.additives) &&
                      this.item.additives.length > 0
                  " class="bg">
                  <v-list-item v-for="additive in this.item.additives" :key="additive.id" class="pt-0 pb-0">
                    <v-list-item-icon>
                      <v-icon color="accent" small>mdi-square</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title class="text-danger">
                        {{ additive.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <p class="success--text" v-else>
                  {{$t('generic.lang_itemWithoutAdditives')}}
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!--end additives-->

          <v-expansion-panels v-if="item.nutritionTable && JSON.stringify(item.nutritionTable).length>2"
            class="elevation-0 bg pa-0 ma-0 pt-2">
            <v-expansion-panel class="elevation-0 bg pa-0 ma-0">
              <v-expansion-panel-header class="elevation-0 pa-0 ma-0">
                <h6 class="text-muted">{{ $t('erp.lang_nutritionTable') }}</h6>
              </v-expansion-panel-header>

              <v-expansion-panel-content class="bg pa-0">
                <v-simple-table style="border-width: 2px !important;">
                  <template v-slot:default>
                    <thead style="border-width: 2px !important;">
                      <tr>
                        <th class="text-left" width="70%">
                          {{$t('erp.lang_nutritionalValue')}} {{item.nutritionTable.unit}}
                        </th>
                        <th class="text-right">
                          {{item.nutritionTable.unit}}
                        </th>
                      </tr>
                    </thead>
                    <tbody style="border-width: 2px !important;">
                      <tr style="border-width: 2px !important;">
                        <td>{{ $t('erp.lang_nutritionKJCal') }}<br />{{ $t('erp.lang_nutritionKcalCal') }}</td>
                        <td class="border-left text-right" style="border-width: 2px !important;">{{
                          item.nutritionTable.calories_kj }} Kj<br />{{item.nutritionTable.calories_kcal}} Kcal</td>
                      </tr>
                      <tr>
                        <td>{{ $t('erp.lang_nutritionCarbohydratesInG') }}</td>
                        <td class="border-left text-right" style="border-width: 2px !important;">{{
                          item.nutritionTable.carbohydrate }} g</td>
                      </tr>
                      <tr>
                        <td>{{ $t('erp.lang_nutritionFatInG') }}</td>
                        <td class="border-left text-right" style="border-width: 2px !important;">{{
                          item.nutritionTable.fat }} g</td>
                      </tr>
                      <tr>
                        <td>{{ $t('erp.lang_nutritionProteinInG') }}</td>
                        <td class="border-left text-right" style="border-width: 2px !important;">{{
                          item.nutritionTable.protein }} g</td>
                      </tr>
                      <tr>
                        <td>{{ $t('erp.lang_nutritionSaltInG') }}</td>
                        <td class="border-left text-right" style="border-width: 2px !important;">{{
                          item.nutritionTable.salt }} g</td>
                      </tr>
                      <tr>
                        <td>{{ $t('erp.lang_nutritionSaturatedFatInG') }}</td>
                        <td class="border-left text-right" style="border-width: 2px !important;">{{
                          item.nutritionTable.saturated_fat }} g</td>
                      </tr>
                      <tr>
                        <td>{{ $t('erp.lang_nutritionSugarInG') }}</td>
                        <td class="border-left text-right" style="border-width: 2px !important;">{{
                          item.nutritionTable.sugars }} g</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ENDPOINTS from "../../plugins/axios/endpoints";
import {createNamespacedHelpers} from "vuex";

export default {
  name: "ProductInfoDialog",

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },

  data(){
    return{
    }
  },

  computed: {
    visible: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    ...createNamespacedHelpers("Config").mapGetters([
      "shop",
      "productInfoSettings",
      "images"
    ]),
  },

  methods: {
    /*close dialog on swipe down*/
    swipeDown() {
      this.visible = false;
    },
  },

  mounted() {

  }
};
</script>

<style scoped>
.top-rounded {
  border-top-left-radius: 50px !important;
  border-top-right-radius: 50px !important;
}

#top-divider {
  width: 50px;
  height: 4px;
  background-color: lightgrey;
  border-radius: 30px;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  top: 5px !important;
}

#header_image {
  min-height: 200px !important;
  background-position: center;
  background-size: cover;
}

#back_btn {
  width: 30px !important;
  height: 30px !important;
  padding: 0 !important;
  border-radius: 13px;
}
</style>
