<template>
  <!--***-->
  <div class="mx-4 mt-5" width="100%">
    <v-card
      :ripple="false"
      id="itemCard"
      elevation="0"
      outlined
      @click="$emit('click')"
      color="product_card"
      class="align-center text-start px-0"
    >
      <v-card-text class="pa-0 ma-0" style="">
        <!--***-->
        <v-list-item three-line class="px-2">
          <v-list-item-content class="py-1">
            <!--start item name-->
            <v-list-item-title
              @click="setCurrent()"
              class="font-weight-medium body-2 mb-0 product_name--text"
            >
              {{ item.name }}
            </v-list-item-title>
            <!--end item name-->

            <!--start item description-->
            <v-list-item-subtitle
              @click="setCurrent()"
              class="product_description--text"
              v-html="item.description"
            >
            </v-list-item-subtitle>
            <!--end item description-->

            <!--start item price-->
            <h6 @click="setCurrent()" class="font-weight-bold body-2">
              <span
                class="primary--text"
                v-if="
                  (isDelivery
                    ? itemGroup['delivery discount']
                    : itemGroup['takeaway discount']) &&
                    (isDelivery
                      ? itemGroup['delivery discount']
                      : itemGroup['takeaway discount'])
                "
              >
                {{ itemPrice.afterDiscount | currency }}
              </span>
              <span
                class="text-muted"
                :class="{
                  'text-decoration-line-through text-muted':
                    itemGroup &&
                    (isDelivery
                      ? itemGroup['delivery discount']
                      : itemGroup['takeaway discount']) > 0,
                  'primary--text': !(
                    itemGroup &&
                    (isDelivery
                      ? itemGroup['delivery discount']
                      : itemGroup['takeaway discount']) > 0
                  )
                }"
              >
                {{ itemPrice.original | currency }}
              </span>
            </h6>
            <!--end item price-->

            <!--start Produktinfo-->
            <div
              style="display: inline-block; width: fit-content !important;"
              v-if="!productInfoSettings.hideProductInfo"
            >
              <p
                @click="details" 
                class="accent--text small"
                style="width: auto; display: inline-block; cursor: pointer;"
              >
                <!--{{$t('generic.lang_productInfo')}}-->
                {{ $t("delivery.lang_productInfo") }}
              </p>
            </div>
            <!--end Produktinfo-->
          </v-list-item-content>

          <!--start item image-->
          <v-list-item-avatar
            class="my-auto"
            @click="setCurrent()"
            :size="$vuetify.breakpoint.xs ? 60 : 80"
            tile
          >
            <v-avatar
              circle
              :size="$vuetify.breakpoint.xs ? 60 : 80"
              color="transparent"
            >
              <img v-if="item.image" :src="item.image" height="120" />
              <img
                v-else
                :src="images && images.productDefaultImage"
                height="120"
              />
            </v-avatar>
          </v-list-item-avatar>
          <!--end item image-->

          <!--start close and open or plus button-->
          <v-list-item-action>
            <v-btn
              v-if="!isCurrent(item.id)"
              elevation="0"
              min-width="30"
              min-height="30"
              dark
              absolute
              color="accent"
              class="position-absolute item_btn"
              @click="setCurrent()"
            >
              <v-icon small>mdi-plus</v-icon>
            </v-btn>

            <v-btn
              v-else
              elevation="0"
              min-width="30"
              min-height="30"
              dark
              absolute
              color="error"
              class="position-absolute item_btn"
              @click="setCurrent()"
            >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
          <!--start close and open or plus button-->
        </v-list-item>
        <!--***-->
      </v-card-text>

      <v-expand-transition>
        <v-card-actions
          v-if="isCurrent(item.id)"
          class="border-top py-0 tertiary lighten-3"
        >
          <v-row no-gutters class="pb-0">
            <v-col cols="12" class="pt-3 pb-3">
              <v-card-text
                v-if="Array.isArray(item.sizes) && item.sizes.length !== 0"
                class="mb-5"
              >
                <span class="subheading">
                  {{ $t("delivery.lang_chooseSize") }}
                </span>

                <v-radio-group v-model="mealsize" column>
                  <v-radio
                    v-for="size in item.sizes"
                    :key="size.id"
                    :value="size"
                  >
                    <template v-slot:label>
                      <div>
                        {{ size.sizeName }}
                        <span class="accent--text font-weight-bold body-2">{{
                          size.sellPrice.toFixed(2) | currency
                        }}</span>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-card-text>

              <!-- start extras -->
              <v-expansion-panels
                v-if="
                  Array.isArray(item.sizes) && item.sizes.length !== 0
                    ? mealsize
                    : true
                "
                style="z-index: 1 !important;"
                class="elevation-0 pa-0 ma-0 pt-2"
                accordion
                flat
              >
                <v-expansion-panel
                  v-for="tab in extraTabs"
                  :key="tab.id"
                  class="elevation-0 pa-0 mx-0 mb-3"
                  style="z-index: 1 !important; border: 1px solid #D8D8D8;"
                >
                  <v-expansion-panel-header class="elevation-0 px-4 py-0 ma-0">
                    <div
                      class="d-flex justify-space-between pb-0 pl-0 pr-4 ma-0"
                    >
                      <h5
                        class="font-weight-bold body-2 ma-0 align-self-center"
                      >
                        {{ tab.tabName }}
                        <span class="font-weight-meduim body-2">
                          {{
                            tab.minChoose == 0 && tab.maxChoose == 0
                              ? ""
                              : tab.minChoose == 1 && tab.maxChoose == 1
                              ? "(" + $t("delivery.lang_select") + " 1)"
                              : tab.minChoose == 1 && tab.maxChoose > 1
                              ? "(" + $t("delivery.lang_selectAtLeast") + " 1)"
                              : tab.maxChoose === tab.minChoose
                              ? "(" +
                                $t("delivery.lang_select") +
                                " " +
                                tab.minChoose +
                                ")"
                              : ""
                          }}
                        </span>
                      </h5>

                      <v-spacer></v-spacer>

                      <span
                        class="font-weight-bolder"
                        :class="
                          (tab.maxChoose > 0 &&
                            totalAmountOfCurrentExtras(tab) >= tab.maxChoose) ||
                          tab.minChoose < 1
                            ? 'text-success'
                            : 'text-danger'
                        "
                      >
                        {{
                          tab.minChoose >= 1
                            ? $t("delivery.lang_required")
                            : $t("generic.lang_optional")
                        }}
                      </span>
                    </div>
                  </v-expansion-panel-header>

                  <!--<v-divider class="pa-0 ma-0"></v-divider>-->

                  <v-expansion-panel-content
                    class="pa-0 pt-3 ma-0 less-padding"
                  >
                    <v-card
                      id="extrasContainer"
                      tile
                      elevation="0"
                      :height="tab.garnish.length > 10 ? '350px' : ''"
                      style="overflow-y: scroll;"
                      width="100%"
                    >
                      <div
                        v-for="ext in tab.garnish"
                        :key="ext.id"
                        style="width: 100%;"
                        class="d-flex pa-1 py-2 mx-0 mb-1 justify-space-between" :class="ext.amount > 0?'booked-garnish':''"
                      >
                        <!--start extras name and price-->
                        <div class="body-2 align-start">
                          {{ ext.name }}
                          <span class="body-2 accent--text ml-1">
                            ({{ ext.sell_price | currency }})
                          </span>
                        </div>
                        <!--end extras name and price-->

                        <!--start extras minus and plus button-->
                        <div class="text-right mr-1">
                          <v-btn
                            v-if="ext.amount > 0"
                            color="accent"
                            class="ma-0"
                            icon
                            outlined
                            rounded
                            x-small
                            :disabled="
                              tab.minChoose > 0 &&
                                totalAmountOfCurrentExtras(tab) > tab.minChoose
                            "
                            @click="removeExtra(ext)"
                          >
                            <v-icon x-small>mdi-minus</v-icon>
                          </v-btn>

                          <span class="mx-2 body-2" v-if="ext.amount > 0">
                            {{ ext.amount }}
                          </span>

                          <v-btn
                            v-if="
                              ext.amount === 0 ||
                                (tab.maxChoose > 0 &&
                                  totalAmountOfCurrentExtras(tab) <
                                    tab.maxChoose) ||
                                tab.maxChoose === 0
                            "
                            color="accent"
                            class="ma-0"
                            icon
                            outlined
                            rounded
                            x-small
                            :disabled="
                              tab.maxChoose > 0 &&
                                totalAmountOfCurrentExtras(tab) >= tab.maxChoose
                            "
                            @click="addExtra(tab, ext)"
                          >
                            <v-icon x-small>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn
                            v-else
                            color="success"
                            class="ma-0"
                            icon
                            outlined
                            rounded
                            x-small
                          >
                            <v-icon x-small>mdi-check</v-icon>
                          </v-btn>
                        </div>
                        <!--end extras minus and plus button-->
                      </div>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <!-- end extras -->
            </v-col>

            <!--start item minus and plus button-->
            <v-col cols="6" class="pb-0 mx-auto text-left">
              <v-text-field
                v-model="amount"
                dense
                outlined
                readonly
                height="40px"
                background-color="white"
                class="text-center centered-input"
              >
                <template v-slot:prepend-inner>
                  <v-icon @click="minus">mdi-minus</v-icon>
                </template>

                <template v-slot:append>
                  <v-icon @click="plus">mdi-plus</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <!--end item minus and plus button-->

            <!--start addToCard button-->
            <v-col cols="6" class="pb-0 text-left">
              <v-btn
                height="40px"
                class="mx-auto"
                block
                elevation="0"
                color="accent"
                :disabled="isValidExtrasLimits(extraTabs)"
                @click="addToCart()"
              >
                {{ itemTotalPrice | currency }}
              </v-btn>
            </v-col>
            <!--end addToCard button-->
          </v-row>
        </v-card-actions>
      </v-expand-transition>
    </v-card>
    <!--start product-info-dialog-->
    <product-info-dialog
      v-model="details_dialog"
      :dialog="this.details_dialog"
      :item="this.item"
    />
  </div>
  <!--end product-info-dialog-->
</template>

<script>
//import plugins
import { createNamespacedHelpers } from "vuex";

//import components
import ProductInfoDialog from "@/components/Menu/ProductInfoDialog";

export default {
  name: "MenuItemsCard",

  components: { ProductInfoDialog },

  props: {
    item: {
      type: Object,
      required: true
    },
    itemGroup: Object
  },

  data() {
    return {
      extraTabs: [],
      extras: [],
      mealsize: null,
      amount: 1,

      details_dialog: false,
      text:
        "ist momentan geschlossen. Bitte versuche es morgen wieder oder wähle ein anderes Restaurant."
    };
  },

  computed: {
    ...createNamespacedHelpers("Order").mapGetters(["isCurrent"]),

    ...createNamespacedHelpers("Config").mapGetters([
      "images",
      "maintenanceMode",
      "offersDelivery",
      "offersPickUp",
      "isDelivery",
      "deliveryInfo",
      "productInfoSettings",
      "shop"
    ]),
    //total price of item
    itemTotalPrice() {
      //item extras total price
      let extrasTotalPrice = 0;
      let price = 0;
      let discount = 0;
      let total = 0;

      this.extraTabs.forEach(extraTab => {
        extrasTotalPrice += extraTab.garnish.reduce((extras_acc, garnish) => {
          return extras_acc + Number(garnish.sell_price) * garnish.amount;
        }, 0);
      });

      if (this.itemGroup) {
        discount = this.isDelivery
          ? this.itemGroup["delivery discount"]
          : this.itemGroup["takeaway discount"];
      }

      if (this.mealsize) {
        price = this.mealsize.sellPrice;
      } else {
        price = this.isDelivery
          ? this.item["delivery price"]
          : this.item["take away price"];
      }

      total = (price + extrasTotalPrice) * this.amount;
      total -= total * (discount / 100);
      return total;
    },
    itemPrice() {
      let price = this.isDelivery
        ? this.item["delivery price"]
        : this.item["take away price"];
      let discount = 0;

      if (this.itemGroup) {
        discount = this.isDelivery
          ? this.itemGroup["delivery discount"]
          : this.itemGroup["takeaway discount"];
      }
      return {
        original: price,
        afterDiscount: price - price * (discount / 100)
      };
    }
  },

  watch: {
    mealsize(val) {
      this.getExtras();
    }
  },

  beforeMount() {},

  methods: {
    ...createNamespacedHelpers("Order").mapMutations(["setToCurrent"]),

    //show product details dialog
    details() {
      this.details_dialog = true;
    },

    //minus QTY of item
    minus() {
      if (this.amount > 1) this.amount = this.amount - 1;
    },

    //plus QTY of item
    plus() {
      this.amount = this.amount + 1;
    },

    //call this function on click on item card
    setCurrent() {
      if (this.isDelivery && !this.maintenanceMode) {
        if (this.offersDelivery) {
          if (!this.deliveryInfo) {
            this.$root.$emit("toggleDeliveryAreaDialog", true);
            return;
          }
        }
      }

      if (!this.maintenanceMode) {
        this.checkExtrasAndSizes();
      }
    },

    //check if item congtain extras and sizes
    checkExtrasAndSizes() {
      if (
        (Array.isArray(this.item.sizes) && this.item.sizes.length > 0) ||
        (Array.isArray(this.item.extras) && this.item.extras.length > 0)
      ) {
        this.extras = [];
        this.mealsize = null;
        this.amount = 1;

        this.getExtras();

        if (!this.isCurrent(this.item.id)) {
          this.setToCurrent(this.item.id);
        } else {
          this.setToCurrent(0);
        }
      } else {
        this.addToCart();
      }
    },

    //add item selected to cart
    addToCart() {
      this.bookExtras();
      let itemToBook = {
        id: this.item.id,
        name: this.item.name,
        sellPrice: this.isDelivery
          ? this.item["delivery price"]
          : this.item["take away price"],
        description: this.item.description,
        amount: this.amount,
        mealsize: this.mealsize,
        garnish: this.extras,
        deliverPrice: this.item["delivery price"],
        takeAwayPrice: this.item["take away price"],
        discount: this.itemGroup
          ? this.isDelivery
            ? this.itemGroup["delivery discount"]
            : this.itemGroup["takeaway discount"]
          : 0,
        itemGroup: this.itemGroup
          ? {
              id: this.itemGroup.id,
              name: this.itemGroup.name,
              "delivery discount": this.itemGroup["delivery discount"],
              "takeaway discount": this.itemGroup["takeaway discount"]
            }
          : null
        //date: "22.05.2022"
        //sizes: this.item.sizes,
        //groupId: this.item.groupId,
        //image: this.item.image,
        //item: this.item,
      };

      /*if (
        this.isBreakfast &&
        moment(parseCurrentQueryToObject().startDate, "DD.MM.YYYY").isValid()
      ) {
        itemToBook.date = parseCurrentQueryToObject().startDate;
      }*/

      this.$store.dispatch("Order/bookItem", itemToBook).then(() => {
        this.extras = [];
        this.mealsize = null;
        this.amount = 1;
        this.setToCurrent(0);
      });
    },

    //get all extras Of current item and pushed to extraTabs
    getExtras() {
      let extraTabs = [];
      this.item.extras.forEach(extra => {
        let items = [];

        extra.garnish.forEach(garnish => {
          if (this.mealsize && this.mealsize.id > 0) {
            if (garnish.sizes && garnish.sizes.length > 0) {
              let size = garnish.sizes.find(
                garnishSize => garnishSize.id === this.mealsize.id
              );
              if (size) {
                items.push({
                  id: garnish.id,
                  name: garnish.name,
                  sell_price: size.sellPrice,
                  sellPriceBrutto: size.sellPrice,
                  tax: garnish.tax,
                  amount: 0
                });
              }
            }
          } else {
            if (garnish.sizes && garnish.sizes.length > 0) {
              let size = garnish.sizes.find(
                garnishSize => garnishSize.id === 0
              );
              if (size) {
                items.push({
                  id: garnish.id,
                  name: garnish.name,
                  sell_price: size.sellPrice,
                  sellPriceBrutto: size.sellPrice,
                  tax: garnish.tax,
                  amount: 0
                });
              }
            } else {
              items.push({
                id: garnish.id,
                name: garnish.name,
                sell_price: garnish.sell_price,
                sellPriceBrutto: garnish.sellPriceBrutto,
                tax: garnish.tax,
                amount: 0
              });
            }
          }
        });
        if (items && items.length > 0) {
          extraTabs.push({
            id: extra.id,
            tabName: extra.tabName,
            minChoose: extra.minChoose,
            maxChoose: extra.maxChoose,
            garnish: items
          });
        }
      });

      this.extraTabs = extraTabs;
    },

    //to minus QTY of current extras
    removeExtra(extra) {
      if (extra.amount > 0) {
        extra.amount--;
      }
    },

    //check current garnish as selected and add QTY
    addExtra(extraTab, extra) {
      let totalAmount = this.totalAmountOfCurrentExtras(extraTab);

      if (extraTab.maxChoose > 0 && totalAmount < extraTab.maxChoose) {
        extra.amount++;
      } else if (extraTab.maxChoose === 0) {
        extra.amount++;
      }
    },

    //calcul total Amount of current extras
    totalAmountOfCurrentExtras(extraTab) {
      let totalAmount = extraTab.garnish.reduce((total, extra) => {
        return total + extra.amount;
      }, 0);

      return totalAmount;
    },

    //check limit of all extras is valid
    isValidExtrasLimits(extraTabs) {
      //CHECK EXTRAS MIN / MAX CHOOSE
      let errorAtTab = null;

      if (this.item.sizes && this.item.sizes.length > 0) {
        if (!this.mealsize) {
          return true;
        }
      }

      extraTabs.forEach((extraTab, index) => {
        let totalAmount = extraTab.garnish.reduce((total, extra) => {
          return total + extra.amount;
        }, 0);

        if (extraTab.minChoose > 0 && totalAmount < extraTab.minChoose) {
          errorAtTab = index;
        }

        if (extraTab.maxChoose > 0 && totalAmount > extraTab.maxChoose) {
          errorAtTab = index;
        }
      });

      if (errorAtTab !== null) {
        //ERROR
        //this.activeExtraTab = errorAtTab;
        //this.garnishBookingError = true;
        return true;
      }

      return false;
    },

    //check garnish selected and add it to extras
    bookExtras() {
      let extras = [];

      this.extraTabs.forEach(extraTab => {
        extraTab.garnish.forEach(extra => {
          if (extra.amount > 0) {
            extras.push(extra);
            extras[extras.length - 1]["tab_id"] = extraTab.id;
          }
        });
      });

      this.extras = extras;
    }
  }
};
</script>

<style scoped>
.item_btn {
  position: absolute !important;
  top: -1px !important;
  right: -1px;
  max-width: 20px !important;
  max-height: 20px !important;
}

.centered-input {
  width: 100px !important;
}

.centered-input >>> input {
  text-align: center;
}

.less-padding .v-expansion-panel-content__wrap {
  padding: 0 10px 0 !important;
}
.booked-garnish{
  background-color: rgba(241, 120, 0, 0.3);
}
</style>
