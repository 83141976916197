<template>
  <div>
    <v-card elevation="0" tile width="100%">
      <!--start menu page header-->
      <v-row no-gutters>
        <v-col cols="12">
          <v-img
            v-if="images"
            :aspect-ratio="16 / 9"
            :height="this.$vuetify.breakpoint.smAndDown ? '300px' : '400px'"
            :src="imageDim(images.header, 'header')"
            :style="{ overflow: 'hidden', position: 'relative' }"
          >
            <div
              :style="
                'background: transparent;position: absolute;' + logoPlacement
              "
            >
              <!--style="position: absolute; left: 20px; top: 145px;"-->
              <div style="max-width:250px;max-height: 250px; ">
                <v-img
                  v-if="images"
                  :src="imageDim(images.logo, 'logo')"
                  class="logo_style mx-2"
                  contain
                  height="100%"
                  max-height="250"
                  max-width="250"
                  width="100%"
                />
              </div>
            </div>
          </v-img>
        </v-col>
      </v-row>
      <!--end menu page header-->

      <!--start restaurant short info-->
      <v-row class="px-5" no-gutters>
        <v-col cols="12" lg="6" md="6" sm="12" xl="6">
          <div
            :class="$vuetify.breakpoint.xs ? 'justify-space-between' : ''"
            class="d-flex"
          >
            <h3 class="font-weight-black mr-2">{{ shop && shop.name }}</h3>

            <div>
              <v-icon
                class="ml-1"
                color="primary"
                medium
                size="35"
                @click="triggerLike"
                v-text="checkIfFav ? 'mdi-heart' : 'mdi-heart-outline'"
              ></v-icon>
              <v-icon
                class="ml-1"
                medium
                size="35"
                style="cursor: pointer;"
                @click="$router.push('/info')"
              >
                mdi-information-outline
              </v-icon>
            </div>
          </div>
        </v-col>

        <!--start Search input-->
        <!--
        <v-col order-sm="3" order-lg="2" order-md="2" order-xl="2" order="3" cols="12" sm="12" md="6"  lg="6" xl="6">
          <v-text-field
            :placeholder="$t('delivery.lang_findDishes')"
            dense class="px-2"
            rounded
            outlined
            autocomlete="false"
            hide-details
          >
            <template v-slot:append>
              <v-btn
                fab
                dark
                elevation="0"
                x-small
                color="transparent"
                @click="isShowingFilter = true"
                id="filter_icon"
              >
                <v-icon dark color="primary" size="24px">
                  mdi-tune
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        -->
        <!--end Search input-->

        <v-col
          cols="12"
          order="2"
          order-lg="3"
          order-md="3"
          order-sm="2"
          order-xl="3"
        >
          <v-card-text
            class="pa-0"
            v-html="shop && shop.description"
          ></v-card-text>
        </v-col>

        <v-col
          align="right"
          cols="12"
          lg="6"
          md="6"
          order="3"
          order-lg="2"
          order-md="2"
          order-sm="3"
          order-xl="2"
          sm="12"
          xl="6"
        >
          <v-list dense>
            <v-list-item class="px-1" dense>
              <v-list-item-content class="py-0">
                <h6 class="ma-0">
                  <strong v-if="isOpen" class="success--text">
                    {{ $t("delivery.lang_opened") }}
                  </strong>
                  <strong v-else class="error--text">
                    {{ $t("delivery.lang_closed") }}
                  </strong>
                </h6>
              </v-list-item-content>
              <v-list-item-icon class="mr-2">
                <font-awesome-icon
                  v-if="isOpen"
                  :icon="['fad', 'door-open']"
                  :style="{ fontSize: '25px' }"
                  class="text-success"
                />
                <font-awesome-icon
                  v-else
                  :icon="['fad', 'door-closed']"
                  :style="{ fontSize: '25px' }"
                  class="error--text"
                />
              </v-list-item-icon>
            </v-list-item>
            <v-list-item v-if="isDelivery" class="px-1" dense>
              <v-list-item-content class="py-0">
                <h6 class="ma-0">
                  {{ $t("delivery.lang_delivery") }}
                </h6>
              </v-list-item-content>
              <v-list-item-icon class="mr-2">
                <font-awesome-icon
                  :icon="['fal', 'biking-mountain']"
                  :style="{ fontSize: '25px' }"
                  class="primary--text"
                />
              </v-list-item-icon>
            </v-list-item>
            <v-list-item v-else class="px-1" dense>
              <v-list-item-content class="py-0">
                <h6 class="ma-0">
                  {{ $t("delivery.lang_pickup") }}
                </h6>
              </v-list-item-content>
              <v-list-item-icon class="mr-2">
                <font-awesome-icon
                  :icon="['fal', 'burger-soda']"
                  :style="{ fontSize: '25px' }"
                  class="accent--text"
                />
              </v-list-item-icon>
            </v-list-item>
            <v-list-item v-if="isDelivery" class="px-1" dense>
              <v-list-item-content class="py-0">
                <h6 class="ma-0">
                  {{ $t("delivery.lang_minimumOrder") }} :
                  {{ $t("delivery.lang_from") }}
                  {{
                    (deliveryInfo ? deliveryInfo.minimumOrderValue : 0)
                      | currency
                  }}
                </h6>
              </v-list-item-content>
              <v-list-item-icon class="mr-2">
                <font-awesome-icon
                  :icon="['fad', 'info-square']"
                  :style="{ fontSize: '30px' }"
                  class="accent--text"
                />
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <!--end restaurant short info-->

      <!--start filter component  -->
      <!--
      <filer-component v-model="isShowingFilter" :dialog="isShowingFilter" />
      -->
      <!--end filter component  -->

      <!--start Products Menu-->
      <menu-items :search="this.search" />
      <!--snd Products Menu-->

      <v-dialog
        v-model="showResetPassword"
        :fullscreen="this.$vuetify.breakpoint.xs"
        class="white mx-auto justify-center align-center"
        fill-height
        max-width="350px"
        overlay-color="black"
        persistent
      >
        <v-card
          class="mx-auto justify-center align-center"
          elevation="0"
          fill-height
          tile
        >
          <v-card-title class="justify-space-between">
            <span></span>

            <v-spacer></v-spacer>

            <v-btn icon @click="showResetPassword = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-window v-model="resetPasswordSteps" touchless>
            <v-window-item :value="1">
              <ForgotPassword
                :reset="true"
                @close="showResetPassword = false"
              />
            </v-window-item>
          </v-window>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
//import plugins
import { createNamespacedHelpers } from "vuex";

//import components
import FilterComponent from "@/components/Menu/FilterComponent.vue";
import MenuItems from "@/components/Menu/MenuItems";
import ENDPOINTS from "../../plugins/axios/endpoints";
import ForgotPassword from "../../components/Auth/ForgotPassword";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faBikingMountain,
  faBurgerSoda
} from "@fortawesome/pro-light-svg-icons";

import {
  faDoorClosed,
  faDoorOpen,
  faInfoSquare
} from "@fortawesome/pro-duotone-svg-icons";

library.add({
  faBurgerSoda,
  faBikingMountain,
  faDoorClosed,
  faDoorOpen,
  faInfoSquare
});
export default {
  name: "Menu",

  components: {
    ForgotPassword,
    FilterComponent,
    MenuItems,
    "font-awesome-icon": FontAwesomeIcon
  },

  data() {
    return {
      isShowingFilter: false,
      showResetPassword: false,
      dialog: true,
      favRestaurants: null,
      resetPasswordSteps: 1
    };
  },

  computed: {
    ...createNamespacedHelpers("Config").mapGetters([
      "images",
      "shop",
      "geoLoc",
      "layout",
      "isOpen",
      "isDelivery",
      "deliveryInfo"
    ]),
    ...createNamespacedHelpers("auth").mapGetters(["isLoggedIn"]),

    //sync search input with vuex
    search: {
      get() {
        return this.$store.getters["Filter/search"];
      },
      set(val) {
        this.$store.commit("Filter/setSearch", val);
      }
    },

    checkIfFav() {
      if (!this.isLoggedIn) {
        return false;
      }
      let domain = window.location.href.split("/");
      if (this.favRestaurants && this.favRestaurants.length > 0) {
        domain = domain && domain.length > 2 ? domain[2] : "";
        domain = domain && domain.length > 0 ? domain.split(".")[0] : "";
        return Boolean(
          this.favRestaurants.find(res => res.subDomain === domain)
        );
      }

      return false;
    },
    logoPlacement() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "left:50%;transform:translate(-50%,-50%);top:50%;";
      }

      if (
        !(
          this.layout &&
          this.layout.hasOwnProperty("logo") &&
          this.layout.logo &&
          this.layout.logo.hasOwnProperty("placement") &&
          this.layout.logo.placement
        )
      ) {
        return "";
      }

      return (
        (this.layout.logo.placement === "left"
          ? "left:0;"
          : this.layout.logo.placement === "center"
          ? "left:50%;transform:translateX(-50%);"
          : "right:0;") + "bottom:10px;"
      );
    }
  },
  methods: {
    triggerLike() {
      if (!this.isLoggedIn) {
        this.$root.$emit("showLogin");
        return;
      }
      this.axios
        .post(ENDPOINTS.ACCOUNT.LIKE.TRIGGER)
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            this.getFavRestaurants();
          }
        })
        .catch(err => {
          if (err.status === 500) {
            this.$swal({
              title: this.$t('delivery.lang_error'),
              icon: "error",
              text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater')
            });
            return;
          }
        })
        .finally(() => {});
    },
    getFavRestaurants() {
      this.axios
        .get(ENDPOINTS.ACCOUNT.FAV)
        .then(res => {
          if (res.status === 200) {
            this.favRestaurants = res.data;
          }
        })
        .catch(err => {
          if (err.status === 500) {
            this.$swal({
              title: this.$t('delivery.lang_error'),
              icon: "error",
              text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater')
            });
            return;
          }
        })
        .finally(() => {});
    },
    imageDim(logo, type) {
      if (!logo) return "";
      let baseUrl = "https://img.3pos.de/unsafe/";
      let url = logo.substring(baseUrl.length);
      let filePath = url.substring(url.split("/")[0].length);

      if (type === "logo") {
        return baseUrl + "0x350/filters:quality(65)" + filePath;
      } else {
        return baseUrl + "0x1920/filters:quality(65)" + filePath;
      }
    },
    async validateEmail() {
      this.$store.commit("Loader/changeStatus", true);
      await this.axios
        .post(ENDPOINTS.DELIVERY.EMAILVALIDATION + this.$route.query.token)
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$swal({
              title: this.$t('delivery.lang_yourEmailHasBeenVerifiedSuccessfully'),
              icon: "success"
            });
            this.$router.replace("/");
          }
        })
        .catch(err => {
          if (err.status === 500) {
            this.$swal({
              title: this.$t('delivery.lang_error'),
              icon: "error",
              text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater')
            });
            return;
          }
        })
        .finally(() => {
          this.$store.commit("Loader/changeStatus", false);
        });
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.getFavRestaurants();
    }
    if (this.$route.query.hasOwnProperty("msg")) {
      this.$swal({
        title: this.$t('delivery.lang_cantCheckout'),
        icon: "warning",
        text: this.$route.query.msg
      });
    }
    if (this.$route.query.hasOwnProperty("token")) {
      this.validateEmail();
    }
    if (this.$route.query.hasOwnProperty("passwordToken")) {
      this.showResetPassword = true;
      this.resetPasswordSteps = 2;
    }
  },
  beforeRouteEnter(from, to, next) {
    next(async vm => {
      await vm.$store
        .dispatch("Config/loadStoreInfo")
        .then(() => {
          vm.$root.$emit("showCloseMSGDialog");
        })
        .catch(err => {
          console.log(err);

          //window.location = 'https://testing.localbee.de?msg=this restaurant is not ready yet'
        });
    });
  }
};
</script>

<style scoped>
#container {
  height: 100% !important;
}

#filter_icon {
  position: absolute;
  right: 4px;
  top: 4px;
}

.logo_style {
  background-color: #fff;
  border-radius: 25px;
}
</style>
